import type { NextPage } from 'next';
import Head from 'next/head';

import { ScopeLogo } from '@assets';

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  } as React.CSSProperties,
};

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Scope Tickets</title>
      </Head>
      <div className='wrapper'>
        <div className='container' style={styles.container}>
          <div className='fallback' style={styles.fallback}>
            <ScopeLogo
              fill='#0500F2'
              style={{
                height: '60px',
                padding: '20px',
                marginBottom: '20px',
              }}
            />
            <h1>Please follow the link sent to you to purchase tickets</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
